<script setup>
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'
import HomePreview from '@/components/customize/HomePreview.vue'
import FormFieldCheckbox from '@/components/FormFieldCheckbox.vue'
import FormFieldError from '@/components/FormFieldError.vue'
import ButtonTypeColorsForm from '@/components/customize/ButtonTypeColorsForm.vue'

const { isLoading, isSaving, selectedPortal } = storeToRefs(usePortalsStore())
</script>

<template>
  <div class="row">
    <div class="col-4">
      <h3>Buttons</h3>
      <label>Customize how buttons appear in the portal.</label>
      <fieldset class="mt-5">
        <h3>Shape</h3>
        <label>Select a button shape: *</label>
        <div class="d-flex mt-2">
          <div class="pe-5">
            <div class="form-check">
              <input
                type="radio"
                class="form-check-input"
                v-model="selectedPortal.fields.customize.globalStyles.buttons.$roundedEnabled.inputValue"
                :disabled="isLoading || !selectedPortal.fields.customize.globalStyles.buttons.$roundedEnabled.editable"
                :value="true"
                :checked="selectedPortal.fields.customize.globalStyles.buttons.$radius.inputValue > 0"
                @change="selectedPortal.fields.customize.globalStyles.buttons.$radius.inputValue = 0"
              />
              <label class="form-check-label" for="buttonShape1">
                Rounded
              </label>
            </div>
            <div class="mt-2 shape-button rounded-button">Rounded</div>
            <div class="mt-2">
              <label>
                Corner radius (pixels):
              </label>
              <div class="d-flex mt-2">
                <div class="left-border-arc"></div>
                <input
                  type="number"
                  class="form-control small-input-number ms-2"
                  v-model.trim="selectedPortal.fields.customize.globalStyles.buttons.$radius.inputValue"
                  :disabled="isLoading || !selectedPortal.fields.customize.globalStyles.buttons.$roundedEnabled.inputValue || !selectedPortal.fields.customize.globalStyles.buttons.$radius.editable"
                  :class="{ 'is-invalid': selectedPortal.fields.customize.globalStyles.buttons.$radius.hasErrors }"
                  @focus="$event.target.select()"
                />
              </div>
              <FormFieldError :fieldModel="selectedPortal.fields.customize.globalStyles.buttons.$radius" />
            </div>
          </div>
          <div class="border-start ps-5">
            <div class="form-check">
              <input
                type="radio"
                class="form-check-input"
                v-model="selectedPortal.fields.customize.globalStyles.buttons.$roundedEnabled.inputValue"
                :disabled="isLoading || !selectedPortal.fields.customize.globalStyles.buttons.$roundedEnabled.editable"
                :value="false"
                :checked="!selectedPortal.fields.customize.globalStyles.buttons.$radius.inputValue"
                @change="selectedPortal.fields.customize.globalStyles.buttons.$radius.inputValue = 0"
              />
              <label class="form-check-label" for="buttonShape2">
                Square
              </label>
            </div>
            <div class="mt-2 shape-button square-button">Square</div>
          </div>
        </div>
      </fieldset>
      <fieldset class="mt-5">
        <h3>Labels</h3>
        <label>The default label capitalization is “Sentence case”</label>
        <div class="form-check mt-2">
          <FormFieldCheckbox
            class="form-check-input"
            :disabled="isLoading || isSaving"
            :fieldModel="selectedPortal.fields.customize.globalStyles.buttons.$textUppercaseEnabled"
          />
          <label class="form-check-label">Use “UPPER CASE” for label capitalization</label>
        </div>
      </fieldset>
      <fieldset class="mt-5">
        <h3>Styles</h3>
        <label>Use hex values to define your button styles.</label>
        <ButtonTypeColorsForm class="mt-3" type="primary" />
        <ButtonTypeColorsForm class="mt-5" type="secondary" />
        <ButtonTypeColorsForm class="mt-5" type="tertiary" />
      </fieldset>
    </div>
    <div class="col-8">
      <HomePreview />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.left-border-arc {
  border-left: solid 1px black;
  border-top: solid 1px black;
  width: 20px;
  height: 36px;
  border-top-left-radius: 40px 40px;
}
.shape-button {
  color: black;
  background-color: #D9D9D9;
  border-color: #D9D9D9;
  width: 130px;
  display: inline-block;
  padding: 15px 18px;
  font-size: 15px;
  text-align: center;
  font-weight: 400;
  border-width: .2px;
  border-style: solid;
}
.square-button {
  border-radius: 0px;
}
.rounded-button {
  border-radius: 200px;
}
</style>
