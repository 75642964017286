<script setup>
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'
import ReturnSummaryPreview from '@/components/customize/ReturnSummaryPreview.vue'
import FormFieldInput from '@/components/FormFieldInput.vue'
import FormFieldError from '@/components/FormFieldError.vue'

const { isLoading, selectedPortal } = storeToRefs(usePortalsStore())
</script>

<template>
  <div class="row">
    <fieldset class="col-4">
      <h3>Return Summary </h3>
      <br>
      <div class="form-group">
        <label class="form-label">Shipping Fee Label *</label>
        <label class="form-label">
          Dependent upon brand requirements the shipping fee label can be updated.
          (Character limit 30)
        </label>
        <br>
        <FormFieldInput
          type="text"
          class="form-control"
          :fieldModel="selectedPortal.fields.customize.content.$returnSummaryShippingFeeLabel"
          :disabled="isLoading"
        />
        <FormFieldError :fieldModel="selectedPortal.fields.customize.content.$returnSummaryShippingFeeLabel" />
      </div>
    </fieldset>
    <div class="col-8">
      <ReturnSummaryPreview />
    </div>
  </div>
</template>
