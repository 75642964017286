<script setup>
import BaseModal from '@/components/BaseModal.vue'
import packing_slip_img from '@/assets/images/packing_slip.svg'
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'
import { ref, computed } from 'vue'
import FormFieldCheckbox from '@/components/FormFieldCheckbox.vue'
import FormFieldNumber from '@/components/FormFieldNumber.vue'
import FormFieldError from '@/components/FormFieldError.vue'
import FormFieldSelect from '@/components/FormFieldSelect.vue'
import FormFieldInput from '@/components/FormFieldInput.vue'
import { SORT_CLASSIFICATION } from '@/constants'

const { isLoading, selectedPortal } = storeToRefs(usePortalsStore())
const inputExtraFieldTitle = ref(null)
let packingSlipModal= ref(null)

function showModal(){
  packingSlipModal.value.show()
}
function changeExtraField() {
  const { fields } = selectedPortal.value
  if (!fields.settings.returns.$mailBackPackingSlipExtraField.inputValue) {
    fields.settings.returns.$mailBackPackingSlipExtraFieldTitle.inputValue = null
  }
  fields.settings.returns.$mailBackPackingSlipExtraFieldTitle.validate()
}

function clearHiddenFields() {
  const { fields } = selectedPortal.value

  if(!fields.settings.returns.$dropOffsIsEnabled.inputValue) {
    fields.settings.returns.$dropOffsFlatFeeAmount.inputValue = null
    fields.settings.returns.$dropOffsDistanceThreshold.inputValue = null
  }
  if(!fields.settings.returns.$homePickupIsEnabled.inputValue) {
    fields.settings.returns.$homePickupFlatFeeAmount.inputValue = null
  }
}
function changeMailBackAltFeeToNull() {
  const { fields } = selectedPortal.value
  fields.settings.returns.$mailBackOnlyMethodOfferedFee.inputValue = null
}

const customerKeepEnabled = computed({
  get() {
    const { fields } = selectedPortal.value
    const supportedSortClassifications = fields.settings.returns.$supportedSortClassifications.inputValue || []
    return supportedSortClassifications.includes(SORT_CLASSIFICATION.CUSTOMER_KEEP)
  },
  set(newValue) {
    const { fields } = selectedPortal.value
    const supportedSortClassifications = fields.settings.returns.$supportedSortClassifications.inputValue || []
    if (newValue) {
      fields.settings.returns.$supportedSortClassifications.inputValue = supportedSortClassifications.concat(SORT_CLASSIFICATION.CUSTOMER_KEEP)
    } else {
      const index = supportedSortClassifications.indexOf(SORT_CLASSIFICATION.CUSTOMER_KEEP)
      if (index > -1) {
        supportedSortClassifications.splice(index, 1)
        fields.settings.returns.$supportedSortClassifications.inputValue = supportedSortClassifications
      }
    }
  }
})
</script>

<template>
  <fieldset>
    <label class="form-label">The following is available in your Returns Portal:</label>
    <div class="form-check">
      <FormFieldCheckbox
        :fieldModel="selectedPortal.fields.settings.returns.$dropOffsIsEnabled"
        :disabled="isLoading"
        @change="clearHiddenFields"
        class="form-check-input"
      />
      <label class="form-check-label">Express Returns Drop-offs</label>
    </div>
    <div class="form-check">
      <FormFieldCheckbox
        :fieldModel="selectedPortal.fields.settings.returns.$homePickupIsEnabled"
        :disabled="isLoading"
        @change="clearHiddenFields"
        class="form-check-input"
      />
      <label class="form-check-label">Express Returns Home Pick-ups</label>
    </div>
    <div class="form-check">
      <input
        type="checkbox"
        class="form-check-input"
        v-model="customerKeepEnabled"
        :disabled="isLoading || !selectedPortal.fields.settings.returns.$supportedSortClassifications.editable"
      />
      <label class="form-check-label">
        Customer Keep
        <a class="text-decoration-none" href="#">Manage Customer Keep Rules <BootstrapIcon name="bs-icon-box-arrow-up-right" /></a>
      </label>
    </div>
    <div class="form-check">
      <FormFieldCheckbox
        :fieldModel="selectedPortal.fields.settings.returns.$giftReturnsAllowedEnabled"
        :disabled="isLoading"
        class="form-check-input"
      />
      <label class="form-check-label">Gift Returns</label>
    </div>
  </fieldset>
  <hr>
  <fieldset>
    <h3 class="mb-3">Mailback</h3>
    <div class="form-group col-3">
      <label class="form-label">Fee (USD) *</label>
      <FormFieldNumber
        class="form-control"
        :fieldModel="selectedPortal.fields.settings.returns.$mailBackFlatFeeAmount"
        placeholder="$0.00"
        @focus="$event.target.select()"
      />
      <FormFieldError :fieldModel="selectedPortal.fields.settings.returns.$mailBackFlatFeeAmount" />
    </div>
    <div class="form-check mt-4 mb-2">
      <FormFieldCheckbox
        :fieldModel="selectedPortal.fields.settings.returns.$mailBackHasOnlyMethodOfferedFeeEnabled"
        @change="changeMailBackAltFeeToNull"
        :disabled="isLoading"
        class="form-check-input"
      />
      <label class="form-check-label">Set an alternative fee when Mailback is the only option available (Optional):</label>
    </div>
    <div class="form-group col-3">
      <label class="form-label">
        Alternative Fee (USD)
        <span v-if="selectedPortal.fields.settings.returns.$mailBackHasOnlyMethodOfferedFeeEnabled.inputValue">*</span>
      </label>
      <FormFieldNumber
        class="form-control"
        :fieldModel="selectedPortal.fields.settings.returns.$mailBackOnlyMethodOfferedFee"
        placeholder="$0.00"
        :disabled="!selectedPortal.fields.settings.returns.$mailBackHasOnlyMethodOfferedFeeEnabled.inputValue"
        @focus="$event.target.select()"
      />
      <FormFieldError :fieldModel="selectedPortal.fields.settings.returns.$mailBackOnlyMethodOfferedFee" />
    </div>
    <div class="mt-4">
      <div>
        <span class="h4">Packing Slip - Extra Fields (Optional) </span>
        <a href="#" class="text-decoration-none ms-2" @click="showModal">See example</a>
      </div>
      <div class="mt-2">Default packing slip fields:</div>
      <ul class="ps-4">
        <li>Order ID</li>
        <li>RMA ID</li>
        <li>Item description</li>
        <li>SKU</li>
        <li>Quantity</li>
        <li>Return reason</li>
      </ul>
    </div>
    <div class="form-group col-3">
      <label for="mailBackPackingSlipExtraField1" class="form-label">Add extra field (Optional)</label>
      <FormFieldSelect
        name="mailBackPackingSlipExtraField"
        id="mailBackPackingSlipExtraField1"
        class="form-select"
        :fieldModel="selectedPortal.fields.settings.returns.$mailBackPackingSlipExtraField"
        @change="changeExtraField"
      >
        <option :value="null">Add extra field</option>
        <option value="product_idenfiter">Product Identifier</option>
        <option value="variant_idenfiter">Variant Identifier</option>
        <option value="upc">UPC</option>
      </FormFieldSelect>
    </div>
    <div class="form-group col-3 mt-4">
      <label class="form-label">Add extra field title (Optional)</label>
      <FormFieldInput
        ref="inputExtraFieldTitle"
        id="inputExtraFieldTitle"
        type="text"
        :fieldModel="selectedPortal.fields.settings.returns.$mailBackPackingSlipExtraFieldTitle"
        @focus="$event.target.select()"
        class="form-control"
      />
      <FormFieldError :fieldModel="selectedPortal.fields.settings.returns.$mailBackPackingSlipExtraFieldTitle" />
    </div>
    <div class="form-check mt-4">
      <FormFieldCheckbox
        :fieldModel="selectedPortal.fields.settings.returns.$mailBackAddVariantsToTitlesEnabled"
        class="form-check-input"
      />
      <label class="form-check-label">Add variants to titles</label>
    </div>
  </fieldset>
  <fieldset v-if="selectedPortal.fields.settings.returns.$dropOffsIsEnabled.inputValue || selectedPortal.fields.settings.returns.$homePickupIsEnabled.inputValue">
      <hr>
      <h3>Express Returns</h3>
      <fieldset v-if="selectedPortal.fields.settings.returns.$dropOffsIsEnabled.inputValue" id="xr_drop_offs_section" class="mt-4">
        <h4>Drop-offs</h4>
        <div class="input-group align-items-center mt-4">
          <div>Show customers drop-off locations within</div>
          <div class="ms-2 me-2" style="width: 50px">
            <FormFieldNumber
              id="dropOffsDistanceThreshold1"
              name="dropOffsDistanceThreshold"
              class="form-control"
              :fieldModel="selectedPortal.fields.settings.returns.$dropOffsDistanceThreshold"
              @focus="$event.target.select()"
              placeholder="0"
            />
          </div>
          <div>miles of their address *</div>
        </div>
        <FormFieldError :fieldModel="selectedPortal.fields.settings.returns.$dropOffsDistanceThreshold" />
        <div class="form-group col-3 mt-4">
          <label class="form-label">Fee (USD) *</label>
          <FormFieldNumber
            class="form-control"
            :fieldModel="selectedPortal.fields.settings.returns.$dropOffsFlatFeeAmount"
            placeholder="$0.00"
            @focus="$event.target.select()"
          />
          <FormFieldError :fieldModel="selectedPortal.fields.settings.returns.$dropOffsFlatFeeAmount" />
        </div>
      </fieldset>
      <fieldset v-if="selectedPortal.fields.settings.returns.$homePickupIsEnabled.inputValue" id="xr_home_pickups_section" class="mt-4">
        <h4>Home Pick-ups</h4>
        <div class="form-group col-3 mt-4">
          <label class="form-label">Fee (USD) *</label>
          <FormFieldNumber
            class="form-control"
            :fieldModel="selectedPortal.fields.settings.returns.$homePickupFlatFeeAmount"
            placeholder="$0.00"
            @focus="$event.target.select()"
          />
          <FormFieldError :fieldModel="selectedPortal.fields.settings.returns.$homePickupFlatFeeAmount" />
        </div>
        <div class="form-check mt-4">
          <FormFieldCheckbox
            class="form-check-input"
            :fieldModel="selectedPortal.fields.settings.returns.$insufficientRefundEnabled"
          />
          <label class="form-check-label">Hide this option when the fee is greater than the customer’s total refund amount, inclusive of exchanges</label>
        </div>
      </fieldset>
  </fieldset>
  <BaseModal title="Example of packing slip" ref="packingSlipModal">
    <template #body>
      <img alt="" :src="packing_slip_img">
    </template>
  </BaseModal>
</template>

<style lang="scss" scoped>
  hr { margin: 50px 0 30px 0; }
</style>

