import { ref, computed } from 'vue'
import {
  isEqual,
  isEmpty,
  isNil,
  isString,
  isObjectLike,
  isPlainObject,
  isBoolean,
  get,
  set,
  cloneDeep,
  pick
} from 'lodash'

export function buildFormState({
  validationSchema,
  initialValues = {},
  fieldsMapping = {},
  editableFieldPaths = ['*']
}) {
  const fieldPaths = getFieldPaths(fieldsMapping)
  const editablePaths = getEditablePaths(fieldPaths)

  const initialValuesRef = ref(cloneDeep(initialValues))
  const values = ref(cloneDeep(initialValues))
  const errors = ref({})
  const valid = computed(() => (isEmpty(errors.value)))
  const fields = buildFieldObjects(fieldsMapping)
  const hasChanges = computed(() => fields.hasChanges.value)
  const editablePathsErrors = computed(() => (pick(errors.value, editablePaths)))
  const editableFieldsValid = computed(() => (isEmpty(editablePathsErrors.value)))

  function getFieldPaths(value) {
    if (isString(value)) {
      return value
    }
    return Object.keys(value).reduce((fieldPaths, key) => (
      fieldPaths.concat(getFieldPaths(value[key]))
    ), [])
  }

  function buildFieldObjects(value) {
    if (isString(value) || isString(value.__path)) {
      const fieldPath = isString(value) ? value : value.__path
      const checkEmptyValuesChanges = isString(value) ? false : value.__checkEmptyValuesChangesEnabled
      return buildFieldInputObject(fieldPath, checkEmptyValuesChanges)
    }
    const children = Object.keys(value).reduce((fields, key) => (
      { ...fields, [key]: buildFieldObjects(value[key]) }
    ), {})
    return {
      hasChanges: computed(() => (
        Object.keys(children).some(fieldKey => children[fieldKey].hasChanges.value)
      )),
      hasErrors: computed(() => (
        Object.keys(children).some(fieldKey => children[fieldKey].hasErrors.value)
      )),
      ...children
    }
  }

  function buildFieldInputObject(fieldPath, checkEmptyValuesChanges) {
    const editable = ref(isFieldEditable(fieldPath))
    const fieldErrors = computed(() => (getFieldErrors(errors.value, fieldPath)))
    return {
      inputValue: computed({
        get() { return get(values.value, fieldPath, null) },
        set(newValue) {
          if (editable.value) {
            setFieldValue(fieldPath, newValue)
          }
        }
      }),
      errors: fieldErrors,
      editable,
      validate: () => { validateField(fieldPath) },
      setError: (error) => { setFieldError(fieldPath, error) },
      clearError: () => { clearFieldError(fieldPath) },
      hasErrors: computed(() => (fieldErrors.value || []).length > 0),
      hasChanges: computed(() => {
        return editable.value && fieldHasChanges(
          initialValuesRef.value,
          values.value,
          fieldPath,
          checkEmptyValuesChanges
        )
      })
    }
  }

  function getFieldErrors(errorsObj, fieldPath) {
    let errorList = []
    Object.keys(errorsObj).forEach(errorKey => {
      if (errorKey === fieldPath || errorKey.startsWith(`${fieldPath}.`) || errorKey.startsWith(`${fieldPath}[`)) {
        errorList = errorList.concat(errorsObj[errorKey])
      }
    })
    return errorList
  }

  function getEditablePaths(paths) {
    if (editableFieldPaths.includes('*')) { return paths }

    return paths.filter(fieldPath => (
      editableFieldPaths.some(editableFieldPath => fieldPath.startsWith(editableFieldPath))
    ))
  }

  function isFieldEditable(fieldPath) {
    return editablePaths.includes(fieldPath)
  }

  function setFieldValue(fieldPath, newValue) {
    set(values.value, fieldPath, newValue)
    validateField(fieldPath)
  }

  function fieldHasChanges(initialValues, currentValues, path, checkEmptyValuesChanges = true) {
    let initialValue = get(initialValues, path)
    let currentValue = get(currentValues, path)
    if (!checkEmptyValuesChanges) {
      if (isEmptyValue(initialValue) && isEmptyValue(currentValue)) {
        return false
      } else {
        initialValue = removeEmptyKeysDeep(initialValue)
        currentValue = removeEmptyKeysDeep(currentValue)
      }
    }
    return !isEqual(initialValue, currentValue)
  }

  function validateField(fieldPath) {
    const error = validateAt(fieldPath, values.value, validationSchema)
    if (error) {
      errors.value = { ...errors.value, [fieldPath]: error }
    } else {
      errors.value = Object.keys(errors.value).reduce((newErrors, key) => {
        const fieldPathArrayRegex = new RegExp(`^${fieldPath.replaceAll('.', '\\.')}\\[\\d+\\]`)
        if (key !== fieldPath && !fieldPathArrayRegex.test(key)) {
          newErrors[key] = errors.value[key]
        }
        return newErrors
      }, {})
    }
    return !error
  }

  function validateAll() {
    errors.value = validate(values.value, validationSchema)
    return valid.value
  }

  function validateOnlyEditable() {
    errors.value = {}
    editablePaths.forEach(fieldPath => (validateField(fieldPath)))
    return valid.value
  }

  function clearFieldError(fieldPath) {
    // eslint-disable-next-line no-unused-vars
    const { [fieldPath]: _error, ...other } = errors.value
    errors.value = other
  }

  function setFieldError(fieldPath, error) {
    errors.value[fieldPath] = error
  }

  function reset(newValues = initialValues) {
    hasChanges.value = false
    initialValuesRef.value = cloneDeep(newValues)
    values.value = cloneDeep(newValues)
    errors.value = {}
  }

  return {
    values,
    errors,
    hasChanges,
    valid,
    editableFieldsValid,
    fields,
    validate: validateAll,
    validateOnlyEditable,
    reset
  }
}

export function validate(values, validationSchema) {
  const errors = {}
  try {
    validationSchema.validateSync(values, { abortEarly: false, context: { values } })
  } catch (error) {
    error.inner.forEach(({ path, message }) => {
      errors[path] = message
    })
  }
  return errors
}

function validateAt(fieldPath, values, validationSchema) {
  try {
    validationSchema.validateSyncAt(fieldPath, values, { context: { values } })
  } catch ({ message }) {
    return message
  }
  return undefined
}

function removeEmptyKeysDeep(object) {
  if (!isPlainObject(object) || isEmptyValue(object)) { return object }

  return Object.keys(object).reduce((newObject, key) => {
    const value = removeEmptyKeysDeep(object[key])

    if (!isEmptyValue(value)) {
      newObject[key] = value
    }

    return newObject
  }, {})
}

function isEmptyValue(value) {
  return isNil(value) ||
    (isObjectLike(value) && isEmpty(value)) ||
      (isString(value) && value.trim().length === 0) ||
        (isBoolean(value) && value === false)
}
