export default Object.freeze({
  orders: {
    $pullUpcEnabled: 'settings.order.pull_upc_enabled',
    $pullImageUrlEnabled: 'settings.order.pull_image_url_enabled',
    $pullVariantsEnabled: 'settings.order.pull_variants_enabled',
    $pullTitleEnabled: 'settings.order.pull_title_enabled',
    $pullDimensionsEnabled: 'settings.order.pull_dimensions_enabled',
    $finalSaleTagEnabled: 'settings.order.final_sale_tag_enabled',
    $shippingRateOverrideEnabled: 'settings.order.shipping_rate_override_enabled',
    $thirdPartyEnabled: 'settings.order.third_party_tag_enabled',
    $returnWindowEligibilityEnabled: 'settings.order.return_window_eligibility_enabled'
  },
  expressReturns: {
    $returnQuantityThreshold: 'settings.express_returns.return_quantity_threshold',
    $returnPriceThreshold: 'settings.express_returns.return_price_threshold'
  },
  warehouseReceiving: {
    $warehouseId: 'settings.warehouse_receiving.warehouse_id',
    $clientId: 'settings.warehouse_receiving.client_id',
    $rmaAdapterVersion: 'settings.warehouse_receiving.rma_adapter_version',
    $returnsManagementEnabled: 'settings.warehouse_receiving.returns_management_enabled',
    $returnsManagementReceivingProgram: 'settings.warehouse_receiving.returns_management_receiving_program',
    $returnsManagementAutoCloseRmasAfterDays: 'settings.warehouse_receiving.returns_management_auto_close_rmas_after_days',
    $thirdPartyLogisticsEnabled: 'settings.warehouse_receiving.third_party_logistics_enabled',
    $thirdPartyLogisticsClientSlug: 'settings.warehouse_receiving.third_party_logistics_client_slug',
    $thirdPartyLogisticsReceivingProgram: 'settings.warehouse_receiving.third_party_logistics_receiving_program',
    $crossdockEnabled: 'settings.warehouse_receiving.crossdock_enabled',
    $crossdockReceivingProgram: 'settings.warehouse_receiving.crossdock_receiving_program'
  }
})
