<script setup>
import { ref, watch, onMounted } from 'vue'

const { fieldModel } = defineProps({
  fieldModel: Object,
  disabled: Boolean
})

const value = ref(null)

onMounted(() => {
  value.value = fieldModel.inputValue
})

watch(value, newValue => {
  const newFieldValue = getFieldModelValue(newValue)
  if (newFieldValue !== fieldModel.inputValue) {
    fieldModel.inputValue = newFieldValue
  }
})

watch(() => fieldModel.inputValue, newValue => {
  if (!isNaN(newValue)) {
    value.value = newValue
  }
})

function getFieldModelValue(value) {
  if (value === null || value === '') { return null }
  if (/^.*\.$/.test(value)) { return NaN }

  return Number(value)
}
</script>

<template>
  <input
    type="text"
    v-model.trim="value"
    :disabled="disabled || !fieldModel.editable"
    :class="{ 'is-invalid': fieldModel.hasErrors }"
  />
</template>
