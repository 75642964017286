<script setup>
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'
import CustomTabs from '@/components/CustomTabs.vue'
import CustomTab from '@/components/CustomTab.vue'
import LogoAssetsForm from '@/components/customize/LogoAssetsForm.vue'
import OrderIdHelpForm from '@/components/customize/OrderIdHelpForm.vue'
import FontsForm from '@/components/customize/FontsForm.vue'
import OrderLookupForm from '@/components/customize/OrderLookupForm.vue'
import HowItWorksForm from '@/components/customize/HowItWorksForm.vue'
import ButtonsStylesForm from '@/components/customize/ButtonsStylesForm.vue'
import SitewideForm from '@/components/customize/SitewideForm.vue'
import RefundForm from '@/components/customize/RefundForm.vue'
import ReturnSummaryForm from '@/components/customize/ReturnSummaryForm.vue'
import IneligibleReturnsModalForm from '@/components/customize/IneligibleReturnsModalForm.vue'

const { selectedPortal } = storeToRefs(usePortalsStore())
</script>

<template>
  <CustomTabs>
    <CustomTab
      title="Global Styles"
      :showHeader="false"
      :draft="selectedPortal.fields.customize.globalStyles.hasChanges"
      :error="selectedPortal.fields.customize.globalStyles.hasErrors"
    >
      <CustomTabs customClass="secondary">
        <CustomTab
          title="Logo Assets"
          :showHeader="false"
          :draft="selectedPortal.fields.customize.globalStyles.logoAssets.hasChanges"
          :error="selectedPortal.fields.customize.globalStyles.logoAssets.hasErrors"
        >
          <LogoAssetsForm />
        </CustomTab>
        <CustomTab
          title="FONTS"
          :showHeader="false"
          :draft="selectedPortal.fields.customize.globalStyles.fonts.hasChanges"
          :error="selectedPortal.fields.customize.globalStyles.fonts.hasErrors"
        >
          <FontsForm />
        </CustomTab>
        <CustomTab
          title="Buttons"
          :showHeader="false"
          :draft="selectedPortal.fields.customize.globalStyles.buttons.hasChanges"
          :error="selectedPortal.fields.customize.globalStyles.buttons.hasErrors"
        >
          <ButtonsStylesForm />
        </CustomTab>
      </CustomTabs>
    </CustomTab>
    <CustomTab
      title="Content"
      :showHeader="false"
      :draft="selectedPortal.fields.customize.content.hasChanges"
      :error="selectedPortal.fields.customize.content.hasErrors"
    >
      <CustomTabs customClass="secondary">
        <CustomTab
          title="SITEWIDE"
          :showHeader="false"
          :draft="selectedPortal.fields.customize.content.sitewide.hasChanges"
          :error="selectedPortal.fields.customize.content.sitewide.hasErrors"
        >
          <SitewideForm />
        </CustomTab>
        <CustomTab
          title="ORDER LOOKUP"
          :showHeader="false"
          :draft="selectedPortal.fields.customize.content.orderLookup.hasChanges"
          :error="selectedPortal.fields.customize.content.orderLookup.hasErrors"
        >
          <OrderLookupForm />
        </CustomTab>
        <CustomTab
          title="ORDER ID HELP MODAL"
          :showHeader="false"
          :draft="selectedPortal.fields.customize.content.orderIdHelpModal.hasChanges"
          :error="selectedPortal.fields.customize.content.orderIdHelpModal.hasErrors"
        >
          <OrderIdHelpForm/>
        </CustomTab>
        <CustomTab
          title="HOW IT WORKS MODAL"
          :showHeader="false"
          :draft="selectedPortal.fields.customize.content.howItWorkModal.hasChanges"
          :error="selectedPortal.fields.customize.content.howItWorkModal.hasErrors"
        >
          <HowItWorksForm/>
        </CustomTab>
        <CustomTab
          title="INELIGIBLE RETURNS MODAL"
          :showHeader="false"
          :draft="selectedPortal.fields.customize.content.ineligibleReturnsModal.hasChanges"
          :error="selectedPortal.fields.customize.content.ineligibleReturnsModal.hasErrors"
        >
          <IneligibleReturnsModalForm />
        </CustomTab>
        <CustomTab
          title="INSTANT STORE CREDIT"
          :showHeader="false"
          :draft="selectedPortal.fields.customize.content.instantStoreCredit.hasChanges"
          :error="selectedPortal.fields.customize.content.instantStoreCredit.hasErrors"
        >
          <RefundForm />
        </CustomTab>
        <CustomTab
          title="RETURN SUMMARY"
          :showHeader="false"
          :draft="selectedPortal.fields.customize.content.$returnSummaryShippingFeeLabel.hasChanges"
          :error="selectedPortal.fields.customize.content.$returnSummaryShippingFeeLabel.hasErrors"
        >
          <ReturnSummaryForm/>
        </CustomTab>
      </CustomTabs>
    </CustomTab>
  </CustomTabs>
</template>
