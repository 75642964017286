<script setup>
import { storeToRefs } from 'pinia'
import { useClientsStore } from '@/stores/clients'
import FormFieldCheckbox from '@/components/FormFieldCheckbox.vue'

const { isLoading, isSaving, selectedClient } = storeToRefs(useClientsStore())
</script>

<template>
  <div>
    <fieldset>
      <h6>Catalog Product Fields (Optional)</h6>
      <label class="form-label">Select the fields that the Returns Portal needs to pull from a customer's order:</label>
      <div class="form-check mt-2">
        <FormFieldCheckbox
          class="form-check-input"
          :disabled="isLoading || isSaving"
          :fieldModel="selectedClient.fields.orders.$pullUpcEnabled"
        />
        <label class="form-check-label">UPC</label>
      </div>
      <div class="form-check mt-2">
        <FormFieldCheckbox
          class="form-check-input"
          :fieldModel="selectedClient.fields.orders.$pullImageUrlEnabled"
          :disabled="isLoading || isSaving"
        />
        <label class="form-check-label">Image URL</label>
      </div>
      <div class="form-check mt-2">
        <FormFieldCheckbox
          class="form-check-input"
          :fieldModel="selectedClient.fields.orders.$pullVariantsEnabled"
          :disabled="isLoading || isSaving"
        />
        <label class="form-check-label">Variants</label>
      </div>
      <div class="form-check mt-2">
        <FormFieldCheckbox
          class="form-check-input"
          :fieldModel="selectedClient.fields.orders.$pullTitleEnabled"
          :disabled="isLoading || isSaving"
        />
        <label class="form-check-label">Title</label>
      </div>
      <div class="form-check mt-2">
        <FormFieldCheckbox
          class="form-check-input"
          :fieldModel="selectedClient.fields.orders.$pullDimensionsEnabled"
          :disabled="isLoading || isSaving"
        />
        <label class="form-check-label">Dimensions</label>
      </div>
    </fieldset>
    <fieldset class="mt-5">
      <h6>Catalog Tags (Optional)</h6>
      <div class="form-check mt-2">
        <FormFieldCheckbox
          class="form-check-input"
          :fieldModel="selectedClient.fields.orders.$finalSaleTagEnabled"
          :disabled="isLoading || isSaving"
        />
        <label class="form-check-label" for="finalSaleTag">Final Sale</label>
      </div>
      <div class="form-check mt-2">
        <FormFieldCheckbox
          class="form-check-input"
          :fieldModel="selectedClient.fields.orders.$shippingRateOverrideEnabled"
          :disabled="isLoading || isSaving"
        />
        <label class="form-check-label">Shipping Rate Override</label>
      </div>
      <div class="form-check mt-2">
        <FormFieldCheckbox
          class="form-check-input"
          :fieldModel="selectedClient.fields.orders.$thirdPartyEnabled"
          :disabled="isLoading || isSaving"
        />
        <label class="form-check-label">Third Party</label>
      </div>
    </fieldset>
    <fieldset class="mt-5">
      <h6>Return Window Eligibility (Optional)</h6>
      <div class="form-check mt-2">
        <FormFieldCheckbox
          class="form-check-input"
          :fieldModel="selectedClient.fields.orders.$returnWindowEligibilityEnabled"
          :disabled="isLoading || isSaving"
        />
        <label class="form-check-label">Use Order Created date to determine return window eligibility</label>
      </div>
    </fieldset>
  </div>
</template>
