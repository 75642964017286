<script setup>
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'
import { toRaw } from 'vue'
import MailbackShippingAccountsTable from '@/components/shipping/MailbackShippingAccountsTable.vue'
import { UNITED_STATES } from '@/constants'
import FormFieldError from '@/components/FormFieldError.vue'
import FormFieldSelect from '@/components/FormFieldSelect.vue'
import FormFieldInput from '@/components/FormFieldInput.vue'

const { isLoading, selectedPortal } = storeToRefs(usePortalsStore())

const stateOptions = getStatesOptions()

function onAddShippingAccount(item) {
  const { fields } = selectedPortal.value
  fields.settings.shipping.$mailbackShippingAccounts.inputValue = (fields.settings.shipping.$mailbackShippingAccounts.inputValue || []).concat([item])
}

function onUpdateShippingAccount(index, values) {
  const { fields } = selectedPortal.value
  const items = toRaw(fields.settings.shipping.$mailbackShippingAccounts.inputValue)
  fields.settings.shipping.$mailbackShippingAccounts.inputValue = [
    ...items.slice(0, index),
    values,
    ...items.slice((index + 1), items.length)
  ]
}

function onRemoveShippingAccount(index) {
  const { fields } = selectedPortal.value
  fields.settings.shipping.$mailbackShippingAccounts.inputValue = (fields.settings.shipping.$mailbackShippingAccounts.inputValue || []).toSpliced(index, 1)
}

function getStatesOptions() {
  return Object.entries(UNITED_STATES).flatMap(([k, v]) => [{label: v, value: k}])
}
</script>

<template>
  <fieldset>
    <h6 class="mb-4">Default Warehouse Shipping Address</h6>
    <div class="form-group col-3">
      <label for="shippingAddressName" class="form-label">Name *</label>
      <FormFieldInput
        type="text"
        name="shippingAddressName"
        id="shippingAddressName"
        :fieldModel="selectedPortal.fields.settings.shipping.$addressName"
        class="form-control"
        :disabled="isLoading"
        @focus="$event.target.select()"
      />
      <FormFieldError :fieldModel="selectedPortal.fields.settings.shipping.$addressName" />
    </div>
    <div class="form-group col-3">
      <label for="shippingAddressCompany" class="form-label">Company *</label>
      <FormFieldInput
        type="text"
        name="shippingAddressCompany"
        id="shippingAddressCompany"
        :fieldModel="selectedPortal.fields.settings.shipping.$addressCompany"
        class="form-control"
        :disabled="isLoading"
        @focus="$event.target.select()"
      />
      <FormFieldError :fieldModel="selectedPortal.fields.settings.shipping.$addressCompany" />
    </div>
    <div class="form-group col-3">
      <label for="shippingAddressStreet1" class="form-label">Street1 *</label>
      <FormFieldInput
        type="text"
        name="shippingAddressStreet1"
        id="shippingAddressStreet1"
        :fieldModel="selectedPortal.fields.settings.shipping.$addressStreet1"
        class="form-control"
        :disabled="isLoading"
        @focus="$event.target.select()"
      />
      <FormFieldError :fieldModel="selectedPortal.fields.settings.shipping.$addressStreet1" />
    </div>
    <div class="form-group col-3">
      <label for="shippingAddressStreet2" class="form-label">Street 2 (Optional)</label>
      <FormFieldInput
        type="text"
        name="shippingAddressStreet2"
        id="shippingAddressStreet2"
        :fieldModel="selectedPortal.fields.settings.shipping.$addressStreet2"
        class="form-control"
        :disabled="isLoading"
        @focus="$event.target.select()"
      />
    </div>
    <div class="form-group col-3">
      <label for="shippingAddressCity" class="form-label">City *</label>
      <FormFieldInput
        type="text"
        name="shippingAddressCity"
        id="shippingAddressCity"
        :fieldModel="selectedPortal.fields.settings.shipping.$addressCity"
        class="form-control"
        placeholder=""
        :disabled="isLoading"
        @focus="$event.target.select()"
      />
      <FormFieldError :fieldModel="selectedPortal.fields.settings.shipping.$addressCity" />
    </div>
    <div class="form-group col-3">
      <label for="shippingAddressState" class="form-label">State *</label>
      <FormFieldSelect
        name="shippingAddressState"
        id="shippingAddressState"
        class="form-select"
        :fieldModel="selectedPortal.fields.settings.shipping.$addressState"
      >
        <option :value="null">Select a State</option>
        <option
          v-for="(option, index) in stateOptions"
          :key="index"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </FormFieldSelect>
      <FormFieldError :fieldModel="selectedPortal.fields.settings.shipping.$addressState" />
    </div>
    <div class="form-group col-3">
      <label for="shippingAddressZipcode" class="form-label">Zipcode *</label>
      <FormFieldInput
        type="text"
        name="shippingAddressZipcode"
        id="shippingAddressZipcode"
        :fieldModel="selectedPortal.fields.settings.shipping.$addressZipcode"
        class="form-control"
        :disabled="isLoading"
        @focus="$event.target.select()"
      />
      <FormFieldError :fieldModel="selectedPortal.fields.settings.shipping.$addressZipcode" />
    </div>
    <br>
    <h6 class="mb-4">Shipping Account</h6>
    <div class="form-group col-12">
      <MailbackShippingAccountsTable
        :items="selectedPortal.fields.settings.shipping.$mailbackShippingAccounts.inputValue"
        :disabled="isLoading || !selectedPortal.fields.settings.shipping.$mailbackShippingAccounts.editable"
        @add="onAddShippingAccount"
        @update="onUpdateShippingAccount"
        @remove="onRemoveShippingAccount"
      />
      <FormFieldError :fieldModel="selectedPortal.fields.settings.shipping.$mailbackShippingAccounts" />
    </div>
  </fieldset>
</template>
<style scoped>
.form-group {
  margin: 15px 0 15px 0;
}
</style>
