<script setup>
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'
import { getPortalUrl } from '@/utils/portal'
import FormFieldInput from '@/components/FormFieldInput.vue'
import FormFieldError from '@/components/FormFieldError.vue'

const { isLoading, selectedPortal } = storeToRefs(usePortalsStore())
</script>

<template>
  <fieldset>
    <h6>Retailer</h6>
    <div>{{ selectedPortal.fields.settings.accountInfo.$retailerName.inputValue }}</div>
    <br>
    <h6>Portal URL</h6>
    <div>{{ getPortalUrl(selectedPortal.fields.settings.accountInfo.$portalUrlPrefix.inputValue) }}</div>
  </fieldset>
  <br>
  <fieldset>
    <h6>Support</h6>
    <label class="form-label">The contact information that customers will use:</label>
    <div class="form-group col-3">
      <label for="retailerPhone1" class="form-label">Phone Number (Optional)</label>
      <FormFieldInput
        name="retailerPhone"
        id="retailerPhone1"
        type="tel"
        class="form-control"
        :fieldModel="selectedPortal.fields.settings.accountInfo.$retailerPhone"
        :disabled="isLoading"
        @focus="$event.target.select()"
      />
      <FormFieldError :fieldModel="selectedPortal.fields.settings.accountInfo.$retailerPhone" />
    </div>
    <div class="form-group col-3">
      <label for="retailerEmail1" class="form-label">(To) Email Address *</label>
      <FormFieldInput
        name="retailerEmail"
        id="retailerEmail1"
        class="form-control"
        :fieldModel="selectedPortal.fields.settings.accountInfo.$retailerEmail"
        :disabled="isLoading"
        @focus="$event.target.select()"
      />
      <FormFieldError :fieldModel="selectedPortal.fields.settings.accountInfo.$retailerEmail" />
    </div>
    <div class="form-group col-3">
      <label for="retailerFromEmail1" class="form-label">(From) Email Address *</label>
      <FormFieldInput
        name="retailerFromEmail"
        id="retailerFromEmail1"
        class="form-control"
        :fieldModel="selectedPortal.fields.settings.accountInfo.$retailerFromEmail"
        :disabled="isLoading"
        @focus="$event.target.select()"
      />
      <FormFieldError :fieldModel="selectedPortal.fields.settings.accountInfo.$retailerFromEmail" />
    </div>
  </fieldset>
</template>

<style scoped>
.form-group {
  margin: 15px 0 15px 0;
}
</style>
