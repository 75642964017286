<script setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'
import closeSvg from '@/assets/images/close.svg'

defineProps({
  header: String
})

const { selectedPortal } = storeToRefs(usePortalsStore())

const bodyFontFamily = computed(() => {
  const { fields } = selectedPortal.value
  return fields.customize.globalStyles.fonts.$bodyBrandFontFamily.inputValue
    ? fields.customize.globalStyles.fonts.$bodyBrandFontFamily.inputValue
    : fields.customize.globalStyles.fonts.$bodyWebFontFamily.inputValue
})

const bodyColor = computed(() => {
  return selectedPortal.value.fields.customize.globalStyles.fonts.$bodyColor.inputValue
})

const mainHeaderFontFamily = computed(() => {
  const { fields } = selectedPortal.value

  if (fields.customize.globalStyles.fonts.$mainHeaderBrandFontFamily.inputValue) {
    return fields.customize.globalStyles.fonts.$mainHeaderBrandFontFamily.inputValue
  }

  return fields.customize.globalStyles.fonts.$mainHeaderWebFontFamily.inputValue || bodyFontFamily.value
})

const headerColor = computed(() => {
  return selectedPortal.value.fields.customize.globalStyles.fonts.$headerColor.inputValue || bodyColor.value
})
</script>

<template>
  <div class="modal-preview d-flex flex-column">
    <div class="d-flex flex-row justify-content-between align-items-center">
      <div class="header" :style="{ 'font-family': mainHeaderFontFamily, 'color': headerColor }">
        {{ header }}
      </div>
      <img alt="" :src="closeSvg" class="close-icon">
    </div>
    <div class="body mt-4" :style="{ 'font-family': bodyFontFamily, 'color': bodyColor }">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-preview {
  border: 1px solid #AAAAAA;
  padding: 40px 35px;

  .header {
    font-size: 24px;
    font-weight: 900;
  }

  .body {
    font-size: 16px;
  }
}
</style>
