<script setup>
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'
import HomePreview from '@/components/customize/HomePreview.vue'
import OrderIdHelpModalPreview from '@/components/customize/OrderIdHelpModalPreview.vue'
import FormFieldCheckbox from '@/components/FormFieldCheckbox.vue'
import FormFieldTextarea from '@/components/FormFieldTextarea.vue'
import FormFieldInput from '@/components/FormFieldInput.vue'
import FormFieldError from '@/components/FormFieldError.vue'
import FormFieldImage from '@/components/FormFieldImage.vue'
import { getImagesLocation } from '@/utils/portal'
import { computed } from 'vue'

const { isLoading, selectedPortal } = storeToRefs(usePortalsStore())

const isOrderIdHelpDisabled = computed(() => {
  return isLoading.value || !selectedPortal.value.fields.customize.content.orderIdHelpModal.$enabled.inputValue
})
function onChangeOrderIdHelpEnabled() {
  const { fields } = selectedPortal.value
  fields.customize.content.orderIdHelpModal.$helpQuestion.inputValue = null
  fields.customize.content.orderIdHelpModal.$header.inputValue = null
  fields.customize.content.orderIdHelpModal.$message.inputValue = null
  fields.customize.content.orderIdHelpModal.$messageOptional1.inputValue = null
  fields.customize.content.orderIdHelpModal.$messageOptional2.inputValue = null
  fields.customize.content.orderIdHelpModal.$image1.inputValue = null
  fields.customize.content.orderIdHelpModal.$image2.inputValue = null
  fields.customize.content.orderIdHelpModal.$image3.inputValue = null
}
</script>
<template>
  <div class="row">
    <div class="col-4">
      <div>
        <h3>Order ID Help Modal</h3>
      </div>
      <div>
        Provide instructions about how to find the order ID. The instructions will appear as a help link/modal on the Order Lookup page.
      </div>
      <br>
      <div class="form-group">
        <FormFieldCheckbox
          class="form-check-input"
          role="switch"
          :fieldModel="selectedPortal.fields.customize.content.orderIdHelpModal.$enabled"
          :disabled="isLoading"
          @change="onChangeOrderIdHelpEnabled"
        />
        <label class="form-label ms-2">Display an Order ID Help Modal</label>
      </div>
      <br>
      <div class="form-group">
        <label class="form-label">
          Order ID help link
          <span v-if="selectedPortal.fields.customize.content.orderIdHelpModal.$enabled.inputValue">*</span>
        </label>
        <FormFieldInput
          type="text"
          class="form-control"
          :fieldModel="selectedPortal.fields.customize.content.orderIdHelpModal.$helpQuestion"
          :disabled="isOrderIdHelpDisabled"
        />
        <FormFieldError :fieldModel="selectedPortal.fields.customize.content.orderIdHelpModal.$helpQuestion" />
      </div>
    </div>
    <div class="col-8">
      <HomePreview />
    </div>
  </div>
  <hr class="border border-dark my-5">
  <div class="row">
    <div class="col-4">
      <div class="form-group">
        <label class="form-label">
          Header
          <span v-if="selectedPortal.fields.customize.content.orderIdHelpModal.$enabled.inputValue">*</span>
        </label>
        <FormFieldInput
          type="text"
          class="form-control"
          :fieldModel="selectedPortal.fields.customize.content.orderIdHelpModal.$header"
          :disabled="isOrderIdHelpDisabled"
        />
        <FormFieldError :fieldModel="selectedPortal.fields.customize.content.orderIdHelpModal.$header" />
      </div>
      <br>
      <div class="form-group">
        <label class="form-label">
          Body Copy
          <span v-if="selectedPortal.fields.customize.content.orderIdHelpModal.$enabled.inputValue">*</span>
        </label>
        <FormFieldTextarea
          class="form-control"
          :disabled="isOrderIdHelpDisabled"
          :fieldModel="selectedPortal.fields.customize.content.orderIdHelpModal.$message"
          rows="5"
        />
        <FormFieldError :fieldModel="selectedPortal.fields.customize.content.orderIdHelpModal.$message" />
      </div>
      <br>
      <div class="form-group">
        <label class="form-label">Image (Optional)</label>
        <label class="form-label">
          600x400 pixels in PNG file format recommended.
          Max file size 2MB.
        </label>
        <div class="my-3"><FormFieldError :fieldModel="selectedPortal.fields.customize.content.orderIdHelpModal.$image1" /></div>
        <FormFieldImage
          :fieldModel="selectedPortal.fields.customize.content.orderIdHelpModal.$image1"
          name="orderIdHelp1"
          :location="getImagesLocation(selectedPortal)"
          :disabled="isOrderIdHelpDisabled"
          :maxSize="2000"
        />
      </div>
      <hr class="border border-dark my-5">
      <div class="form-group">
        <label class="form-label">Body Copy 2 (Optional)</label>
        <FormFieldTextarea
          class="form-control"
          placeholder="Add a second message"
          :disabled="isOrderIdHelpDisabled"
          :fieldModel="selectedPortal.fields.customize.content.orderIdHelpModal.$messageOptional1"
          rows="5"
        />
        <FormFieldError :fieldModel="selectedPortal.fields.customize.content.orderIdHelpModal.$messageOptional1" />
      </div>
      <br>
      <div class="form-group">
        <label class="form-label">Image 2 (Optional)</label>
        <label class="form-label">
          600x400 pixels in PNG file format recommended.
          Max file size 2MB.
        </label>
        <div class="my-3"><FormFieldError :fieldModel="selectedPortal.fields.customize.content.orderIdHelpModal.$image2" /></div>
        <FormFieldImage
          :fieldModel="selectedPortal.fields.customize.content.orderIdHelpModal.$image2"
          name="orderIdHelp2"
          :location="getImagesLocation(selectedPortal)"
          :disabled="isOrderIdHelpDisabled"
          :maxSize="2000"
        />
      </div>
      <hr class="border border-dark my-5">
      <div class="form-group">
        <label class="form-label">Body Copy 3 (Optional)</label>
        <FormFieldTextarea
          class="form-control"
          placeholder="Add a third message"
          :disabled="isOrderIdHelpDisabled"
          :fieldModel="selectedPortal.fields.customize.content.orderIdHelpModal.$messageOptional2"
          rows="5"
        />
        <FormFieldError :fieldModel="selectedPortal.fields.customize.content.orderIdHelpModal.$messageOptional2" />
      </div>
      <br>
      <div class="form-group">
        <label class="form-label">Image 3 (Optional)</label>
        <label class="form-label">
          600x400 pixels in PNG file format recommended.
          Max file size 2MB.
        </label>
        <div class="my-3"><FormFieldError :fieldModel="selectedPortal.fields.customize.content.orderIdHelpModal.$image3" /></div>
        <FormFieldImage
          :fieldModel="selectedPortal.fields.customize.content.orderIdHelpModal.$image3"
          name="orderIdHelp3"
          :location="getImagesLocation(selectedPortal)"
          :disabled="isOrderIdHelpDisabled"
          :maxSize="2000"
        />
      </div>
    </div>
    <div class="col-8">
      <OrderIdHelpModalPreview />
    </div>
  </div>
</template>

