export const AUTH_TOKEN_STORAGE_KEY = 'storage.auth_token'

export const DEFAULT_PORTAL_URL_SUFFIX = 'returns.optiturn.com'

export const ALERT_TYPES = Object.freeze({
  ERROR: 'danger',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning'
})

export const EXCHANGE_OFFERS = Object.freeze({
  COLOR: 'color',
  DAMAGED: 'damaged',
  OTHER: 'other',
  REFUND: 'refund',
  SIZE: 'size'
})

export const MAIL_CARRIERS = Object.freeze({
  UPS: 'UPS',
  FEDEX: 'fedex'
})

export const UPS_MAIL_INNOVATIONS = 'ups_mail_innovations_domestic'

export const UPS_SERVICE_LEVELS = Object.freeze({
  "UPS Ground": 'ups_ground',
  "UPS Mail Innnovations": UPS_MAIL_INNOVATIONS,
})

export const FEDEX_SERVICE_LEVELS = Object.freeze({
  "Fedex Ground": 'fedex_ground',
  "Fedex Ground Economy": 'fedex_ground_economy',
  "Fedex Ground Economy Returns": 'fedex_ground_economy_returns'
})

export const UNITED_STATES = Object.freeze({
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming'
})

export const URL_REGEX = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/

export const WEB_FONTS = [
  'Andalé Mono',
  'Arial',
  'Arial Black',
  'Arimo',
  'Avenir',
  'Baskerville',
  'Bradley Hand',
  'Brush Script MT',
  'Courier',
  'Courier New',
  'Comic Sans MS',
  'Georgia',
  'Gill Sans',
  'Helvetica',
  'Impact',
  'Lucida',
  'Luminari',
  'Monaco',
  'Palatino',
  'Tahoma',
  'Times New Roman',
  'Trebuchet MS',
  'Verdana'
]

export const HEX_COLOR_REGEX = /^#([a-fA-F\d]{3}|[a-fA-F\d]{6}|[a-fA-F\d]{8})$/

export const ASSETS_IMAGES_PATH = 'assets/images'

export const ASSETS_FONTS_PATH = 'assets/fonts'

export const RMA_ADAPTER_SUPPORTED_VERSIONS = [1, 2, 3]

export const PORTAL_STATUS = Object.freeze({
  PENDING: 'pending',
  READY: 'ready'
})

export const CUSTOMER_USER_RIGHT = 'use_returns_portal_management_customer'

export const SORT_CLASSIFICATION = Object.freeze({
  CUSTOMER_KEEP: 'customer_keep'
})
