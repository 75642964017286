<script setup>
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useClientsStore } from '@/stores/clients'
import { usePortalsStore } from '@/stores/portals'
import PortalNavLink from '@/components/PortalNavLink.vue'
import DraftIcon from "@/components/DraftIcon.vue"
import { getPortalName, isReady } from '@/utils/portal'

const route = useRoute()
const router = useRouter()
const userStore = useUserStore()
const clientsStore = useClientsStore()
const portalsStore = usePortalsStore()
const otUrl = import.meta.env.VITE_OPTITURN_URL
const csrUrl = import.meta.env.VITE_CSR_URL

const { selectedClient } = storeToRefs(clientsStore)

const {
  isSaving,
  isPublishing,
  portals,
  selectedPortal,
  canSaveDraft,
  canPublish
} = storeToRefs(portalsStore)

const { canEditDevelopersSection } = storeToRefs(userStore)

const isLoading = computed(() => {
  return userStore.isLoading || clientsStore.isLoading || portalsStore.isLoading
})

const readyPortals = computed(() => (portals.value.filter(isReady)))

function onViewAll() {
  const { slug } = selectedClient.value.values
  router.push({ name: 'clientDetail', params: { clientSlug: slug } })
}

function onSelectPortal({ values }) {
  const { slug } = selectedClient.value.values
  const { identifier } = values
  router.push({
    name: route.name,
    params: {
      clientSlug: slug,
      portalIdentifier: identifier
    }
  })
}
</script>

<template>
  <div class="d-flex flex-fill">
    <div 
      class="nav-container
             bg-light border
             border-light
             border-top-0
             border-bottom-0"
    >
      <ul class="nav d-flex flex-column">
        <li class="nav-item">
          <div class="dropdown">
            <button
              class="btn
                     retailer-dropdown-toggle
                     w-100
                     bg-white
                     d-flex
                     align-items-center
                     justify-content-between"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              :disabled="isLoading"
            >
              <b>{{ getPortalName(selectedPortal?.values || {}) }}</b>
              <BootstrapIcon name="bs-icon-chevron-expand" />
            </button>
            <ul class="dropdown-menu w-100">
              <li>
                <router-link
                  to="/"
                  class="dropdown-item"
                  @click="onViewAll()"
                >
                  View All
                </router-link>
              </li>
              <li v-for="(portal, index) in readyPortals" :key="index">
                <a
                  class="dropdown-item d-flex justify-content-between align-items-center"
                  href="#"
                  @click="onSelectPortal(portal)"
                >
                  {{ getPortalName(portal.values) }}
                  <DraftIcon v-if="portal.hasChanges"/>
                </a>
              </li>
            </ul>
          </div>
        </li>
        <li class="nav-item mt-3">
          <a class="nav-link link-dark d-flex align-items-center" :href="csrUrl">
            Manage Returns&nbsp;<BootstrapIcon name="bs-icon-box-arrow-up-right" />
          </a>
        </li>
        <li class="nav-item">
          <PortalNavLink
            name="policies"
            :draft="selectedPortal?.fields?.policies?.hasChanges"
            :error="selectedPortal?.fields?.policies?.hasErrors"
          >
            Policies
          </PortalNavLink>
        </li>
        <li class="nav-item">
          <PortalNavLink
            name="customize"
            :draft="selectedPortal?.fields?.customize?.hasChanges"
            :error="selectedPortal?.fields?.customize?.hasErrors"
          >
            Customize
          </PortalNavLink>
        </li>
        <li class="nav-item">
          <a class="nav-link link-dark d-flex align-items-center" :href="otUrl+'/users/'">
            Users&nbsp;<BootstrapIcon name="bs-icon-box-arrow-up-right" />
          </a>
        </li>
        <li class="nav-item">
          <PortalNavLink
            name="settings"
            :draft="selectedPortal?.fields?.settings?.hasChanges"
            :error="selectedPortal?.fields?.settings?.hasErrors"
          >
            Settings
          </PortalNavLink>
        </li>
        <li class="nav-item">
          <PortalNavLink
            v-if="canEditDevelopersSection"
            name="developers"
            :query="route.query"
            :draft="selectedPortal?.fields?.developers?.hasChanges"
            :error="selectedPortal?.fields?.developers?.hasErrors"
          >
            Developers
          </PortalNavLink>
        </li>
        <hr>
        <li class="nav-item">
          <a class="nav-link link-dark d-flex align-items-center" :href="otUrl+'/help_center/'">
            Help Center&nbsp;<BootstrapIcon name="bs-icon-box-arrow-up-right" />
          </a>
        </li>
      </ul>
    </div>
    <div class="d-flex flex-fill">
      <div class="d-flex flex-column flex-fill px-6 py-4">
        <div class="d-flex align-items-center justify-content-between">
          <div>
             <h1>{{ route.meta.title }}</h1>
            <div>* indicates a required field</div>
          </div>
          <div>
            <button
              class="btn btn-outline-primary"
              type="button"
              :disabled="isLoading || !canSaveDraft"
              @click="portalsStore.saveDraftSelectedPortal()"
            >
              <span v-if="isSaving" class="spinner-border spinner-border-sm me-1"></span>
              SAVE DRAFT
            </button>
            <button
              class="btn btn-primary ms-3"
              type="button"
              :disabled="isLoading || !canPublish"
              @click="portalsStore.publishSelectedPortal()"
            >
              <span
                v-if="isPublishing"
                class="spinner-border spinner-border-sm me-1"
              >
              </span>
              PUBLISH
            </button>
          </div>
        </div>
        <div v-if="selectedPortal" class="d-flex flex-column flex-fill mt-3 mb-3">
          <RouterView />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.nav-container {
  min-width: 200px;

  .retailer-dropdown-toggle {
    border-color: var(--bs-border-color);
    border-radius: 0;
    border-right: 0;
    border-left: 0;
    padding: 16px 12px;
  }

  hr {
    margin-left: 16px;
    margin-right: 16px;
  }
}
</style>
