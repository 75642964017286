<script setup>
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'
import RefundPreview from '@/components/customize/RefundPreview.vue'
import FormFieldInput from '@/components/FormFieldInput.vue'
import FormFieldError from '@/components/FormFieldError.vue'
import FormFieldTextarea from '@/components/FormFieldTextarea.vue'

const { isLoading, selectedPortal } = storeToRefs(usePortalsStore())
</script>

<template>
  <div class="row">
    <div class="col-4">
      <h3>Instant Store Credit</h3>
      <fieldset class="mt-5">
        <div>
          <label class="form-label">Header *</label>
          <FormFieldInput
            type="text"
            class="form-control"
            :fieldModel="selectedPortal.fields.customize.content.instantStoreCredit.$headline"
            :disabled="isLoading"
          />
          <FormFieldError :fieldModel="selectedPortal.fields.customize.content.instantStoreCredit.$headline" />
        </div>
        <div class="mt-3">
          <label class="form-label">
            <div>Sub-header *</div>
            <div>(Character limit 64)</div>
          </label>
          <FormFieldTextarea
            class="form-control"
            :disabled="isLoading"
            :fieldModel="selectedPortal.fields.customize.content.instantStoreCredit.$subcopy"
          />
          <FormFieldError :fieldModel="selectedPortal.fields.customize.content.instantStoreCredit.$subcopy" />
        </div>
        <div class="mt-3">
          <label class="form-label">
            <div>Disclaimer (Optional)</div>
            <div>(Character limit 140, can wrap up to 2 lines)</div>
          </label>
          <FormFieldTextarea
            class="form-control"
            :disabled="isLoading"
            :fieldModel="selectedPortal.fields.customize.content.instantStoreCredit.$disclaimer"
          />
          <FormFieldError :fieldModel="selectedPortal.fields.customize.content.instantStoreCredit.$disclaimer" />
        </div>
      </fieldset>
    </div>
    <div class="col-8">
      <RefundPreview />
    </div>
  </div>
</template>
