<script setup>
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'
import CustomTabs from '@/components/CustomTabs.vue'
import CustomTab from '@/components/CustomTab.vue'
import JsonEditor from '@/components/JsonEditor.vue'
import FormFieldError from '@/components/FormFieldError.vue'

const { selectedPortal } = storeToRefs(usePortalsStore())

function onPoliciesJsonError(errors = []) {
  if (errors.length > 0) {
    selectedPortal.value.fields.developers.$policiesJson.setError('Policies JSON is invalid')
  } else {
    selectedPortal.value.fields.developers.$policiesJson.validate()
  }
}

function onStringsJsonError(errors = []) {
  if (errors.length > 0) {
    selectedPortal.value.fields.developers.$stringsJson.setError('Strings JSON is invalid')
  } else {
    selectedPortal.value.fields.developers.$stringsJson.validate()
  }
}

function onStylesJsonError(errors = []) {
  if (errors.length > 0) {
    selectedPortal.value.fields.developers.$stylesJson.setError('Styles JSON is invalid')
  } else {
    selectedPortal.value.fields.developers.$stylesJson.validate()
  }
}

function onConfigJsonError(errors = []) {
  if (errors.length > 0) {
    selectedPortal.value.fields.developers.$configJson.setError('Config JSON is invalid')
  } else {
    selectedPortal.value.fields.developers.$configJson.validate()
  }
}
</script>

<template>
  <CustomTabs>
    <CustomTab
      title="Policies JSON"
      :showHeader="false"
      :draft="selectedPortal.fields.developers.$policiesJson.hasChanges"
      :error="selectedPortal.fields.developers.$policiesJson.hasErrors"
    >
      <h6>Policies JSON *</h6>
      <FormFieldError class="mb-2" :fieldModel="selectedPortal.fields.developers.$policiesJson" />
      <JsonEditor
        v-if="selectedPortal.fields.developers.$policiesJson.editable"
        v-model="selectedPortal.fields.developers.$policiesJson.inputValue"
        @onerror="onPoliciesJsonError"
      />
      <div v-else>You do not have permissions to access this section</div>
    </CustomTab>
    <CustomTab
      title="Strings JSON"
      :showHeader="false"
      :draft="selectedPortal.fields.developers.$stringsJson.hasChanges"
      :error="selectedPortal.fields.developers.$stringsJson.hasErrors"
    >
      <h6>Strings JSON *</h6>
      <FormFieldError class="mb-2" :fieldModel="selectedPortal.fields.developers.$stringsJson" />
      <JsonEditor
        v-if="selectedPortal.fields.developers.$stringsJson.editable"
        v-model="selectedPortal.fields.developers.$stringsJson.inputValue"
        @onerror="onStringsJsonError"
      />
      <div v-else>You do not have permissions to access this section</div>
    </CustomTab>
    <CustomTab
      title="Styles JSON"
      :showHeader="false"
      :draft="selectedPortal.fields.developers.$stylesJson.hasChanges"
      :error="selectedPortal.fields.developers.$stylesJson.hasErrors"
    >
      <h6>Styles JSON *</h6>
      <FormFieldError class="mb-2" :fieldModel="selectedPortal.fields.developers.$stylesJson" />
      <JsonEditor
        v-if="selectedPortal.fields.developers.$stylesJson.editable"
        v-model="selectedPortal.fields.developers.$stylesJson.inputValue"
        @onerror="onStylesJsonError"
      />
      <div v-else>You do not have permissions to access this section</div>
    </CustomTab>
    <CustomTab
      title="Config JSON"
      :showHeader="false"
      :draft="selectedPortal.fields.developers.$configJson.hasChanges"
      :error="selectedPortal.fields.developers.$configJson.hasErrors"
    >
      <h6>Config JSON *</h6>
      <FormFieldError class="mb-2" :fieldModel="selectedPortal.fields.developers.$configJson" />
      <JsonEditor
        v-if="selectedPortal.fields.developers.$configJson.editable"
        v-model="selectedPortal.fields.developers.$configJson.inputValue"
        @onerror="onConfigJsonError"
      />
      <div v-else>You do not have permissions to access this section</div>
    </CustomTab>
  </CustomTabs>
</template>
