<script setup>
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'
import CustomTabs from '@/components/CustomTabs.vue'
import CustomTab from '@/components/CustomTab.vue'
import TimeWindowsForm from '@/components/policies/TimeWindowsForm.vue'
import ReturnReasonsForm from '@/components/policies/ReturnReasonsForm.vue'

const { selectedPortal } = storeToRefs(usePortalsStore())
</script>

<template>
  <CustomTabs>
    <CustomTab
      title="Time Windows"
      :draft="selectedPortal.fields.policies.timeWindows.hasChanges"
      :error="selectedPortal.fields.policies.timeWindows.hasErrors"
    >
      <TimeWindowsForm />
    </CustomTab>
    <CustomTab
      title="Return Reasons"
      :draft="selectedPortal.fields.policies.$returnReasons.hasChanges"
      :error="selectedPortal.fields.policies.$returnReasons.hasErrors"
    >
      <ReturnReasonsForm 
        :disabled="!selectedPortal.fields.policies.$returnReasons.editable"
      />
    </CustomTab>
  </CustomTabs>
</template>
